@import url('https://fonts.googleapis.com/css2?family=Italiana&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('./images/bg.png');
  min-height: 100vh;
  background-position: center;
  /* background-size: cover; */
  background-size: cover;
    background-repeat: no-repeat;
}
.logo{
  margin-inline: auto;
  width:  calc(280px + (450 - 280) * ((100vw - 320px) / (1920 - 320)));
}
.heading{
  font-family: "Italiana", sans-serif;
  color: white;
  font-size:   calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
}
.para{
  font-family: "Inter", sans-serif;
  color: white;
  font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 200;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-group {
  border-radius: 50px;
  padding-block: 5px;
  padding-inline: 5px;
  border: 1px solid white;
}
.input-group input:focus{
  background: transparent;
  box-shadow: none;
  color: white;
}
.input-group input::placeholder{
  color: white;
}
.input-group input{
  background: transparent;
  border: 0;
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
  color: white;
}

.input-group span{
  background: white;
  border-radius: 50px !important;
  font-family: "Inter", sans-serif;
  padding-inline: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
  padding-block: 10px;
}
.min-vh-75{
  min-height: 75vh;
}
.social{
  margin-inline: 10px;
  width: 40px;
  height: 40px;
}
